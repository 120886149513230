/**
 * @generated SignedSource<<015ef49a7a9cc84a8a0dc994d5c8741d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ResourceAllocationFormItemsQuery$variables = {
  projectID: string;
};
export type ResourceAllocationFormItemsQuery$data = {
  readonly accessible_scaling_groups: ReadonlyArray<{
    readonly accelerator_quantum_size: number | null | undefined;
    readonly is_active: boolean | null | undefined;
    readonly name: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export type ResourceAllocationFormItemsQuery = {
  response: ResourceAllocationFormItemsQuery$data;
  variables: ResourceAllocationFormItemsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "project_id",
        "variableName": "projectID"
      }
    ],
    "concreteType": "ScalingGroup",
    "kind": "LinkedField",
    "name": "accessible_scaling_groups",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accelerator_quantum_size",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_active",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResourceAllocationFormItemsQuery",
    "selections": (v1/*: any*/),
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResourceAllocationFormItemsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c552c3b18a1c95ba2bb0654df987466d",
    "id": null,
    "metadata": {},
    "name": "ResourceAllocationFormItemsQuery",
    "operationKind": "query",
    "text": "query ResourceAllocationFormItemsQuery(\n  $projectID: UUID!\n) {\n  accessible_scaling_groups(project_id: $projectID) {\n    accelerator_quantum_size\n    name\n    is_active\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0af356167880d4ac25882383efaaab4";

export default node;
