/**
 * @generated SignedSource<<8e04ae2391a4176f78416abc224e24a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SessionNodesFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string | null | undefined;
  readonly row_id: string;
  readonly status: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"SessionReservationFragment" | "SessionSlotCellFragment" | "SessionStatusTagFragment" | "SessionUsageMonitorFragment">;
  readonly " $fragmentType": "SessionNodesFragment";
} | null | undefined>;
export type SessionNodesFragment$key = ReadonlyArray<{
  readonly " $data"?: SessionNodesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SessionNodesFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SessionNodesFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      "action": "NONE"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "row_id",
        "storageKey": null
      },
      "action": "NONE"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SessionStatusTagFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SessionReservationFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SessionSlotCellFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SessionUsageMonitorFragment"
    }
  ],
  "type": "ComputeSessionNode",
  "abstractKey": null
};

(node as any).hash = "529961d4450944f1c138d21c87f8f3ba";

export default node;
